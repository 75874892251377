<template>

    <XModal ref="XModal" :show-overlay="true">
        <template #header>
            Выбор участника
        </template>

        <!--        <TablePage ref="TablePage" main-url="/gas-reference">


                </TablePage>-->

        <div :style="{minWidth: '60vw', maxWidth: '80vw'}">
            <GasReference ref="GasReference" :addSelectAbonentButton="true" :preserve-load-table-items-on-create="true"
                          @abonentSelected="abonentSelected"/>
        </div>


    </XModal>

</template>

<script>

import {XModal,} from "@xnpmpackages/xcomponents";
import GasReference from "@/components/app/GasReference";

export default {
    name: "AbonentSelector",
    data() {
        return {
            promise_resolve: null,
        }
    },
    methods: {

        select($options) {

            console.log($options);

            return new Promise((resolve) => {

                this.promise_resolve = resolve;
                this.$refs.XModal.show().then(() => {

                    this.$refs.GasReference.$refs.TablePage.setCustomFilters($options?.custom_filters);

                    if ($options?.filters) {
                        this.$refs.GasReference.$refs.TablePage.filters = $options?.filters;
                    }

                    this.$refs.GasReference.$refs.TablePage.getTableRows(true);

                });


            });

        },
        async abonentSelected(data) {
            await this.$refs.XModal.hide();
            this.promise_resolve(data);
        }

    },
    components: {
        GasReference,
        XModal,
        /*TablePage*/
    }
}
</script>

<style scoped>

</style>