<template>
    <router-view></router-view>
</template>

<script>
import {AppEventBus} from '@xnpmpackages/xcomponents';

export default {
    themeChangeListener: null,
    mounted() {
        this.themeChangeListener = (event) => {
            const elementId = 'theme-link';
            const linkElement = document.getElementById(elementId);
            const cloneLinkElement = linkElement.cloneNode(true);
            const newThemeUrl = linkElement.getAttribute('href').replace(this.$appState.theme, event.theme);

            cloneLinkElement.setAttribute('id', elementId + '-clone');
            cloneLinkElement.setAttribute('href', newThemeUrl);
            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', elementId);
            });
            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            this.$appState.theme = event.theme;
            this.$appState.darkTheme = event.dark;
        };

        AppEventBus.on('theme-change', this.themeChangeListener);


        this.changeTheme = (theme) => {

            localStorage.setItem('theme', theme);

            switch (theme) {

                case 'dark':
                    AppEventBus.emit('theme-change', {theme: 'vela-blue', dark: true});
                    break;

                case 'light':
                    AppEventBus.emit('theme-change', {theme: 'mdc-light-indigo-no-outline', dark: false});
                    break;


            }

        }

        AppEventBus.on('theme-change-preset', this.changeTheme);

        const theme = localStorage.getItem('theme');
        if (theme)
            this.changeTheme(theme);

    },
    beforeUnmount() {
        AppEventBus.off('theme-change', this.themeChangeListener);
    }
}
</script>