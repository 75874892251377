
import {defineComponent} from 'vue'
import AbonentSelector from "@/components/Constructor/AbonentSelector.vue";

export default defineComponent({
  name: "FilterDestinations",
  props: {
    modelValue: null,
  },
  emits: ['update:modelValue'],
  components: {AbonentSelector},
  data() {
    return {
      innerValue: [],
    }
  },
  methods: {

    async addDst() {

      if (!this.innerValue)
        this.innerValue = []

      const new_item = await this.$refs.AbonentSelector.select()

      const exists = this.innerValue.some(item => item.guid === new_item.guid)
      if (exists)
        return;

      this.innerValue.push(new_item)
      this.emitNewValue()

    },
    async removeDst($event) {

      this.innerValue = this.innerValue.filter(item => item.guid !== $event.value[0].guid)

      this.emitNewValue()

    },
    emitNewValue() {
      this.$emit('update:modelValue', this.innerValue)
    }
  },
  watch: {

    modelValue() {
      this.innerValue = this.modelValue
    }

  },
  mounted() {
    this.innerValue = this.modelValue
  }


})
