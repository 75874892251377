<template>

    <TablePage ref="TablePage" :preserve-load-table-items-on-create="true" main-url="/gas-reference"
               @tableItemsClick="tableItemsClick">

        <template #tableColumns="TableProps">

            <Column :sortable="true" field="title" filter-field="title">
                <template #header>
                    <SearchInput v-model="TableProps.filters['title']" label="Наименование" style="min-width: 150px;"
                                 @keyup.enter="$refs.TablePage.getTableRows(true)"/>
                </template>
            </Column>

            <Column :sortable="true" field="organization" filter-field="organization">
                <template #header>
                    <SearchInput v-model="TableProps.filters['organization']" label="Организация"
                                 @keyup.enter="$refs.TablePage.getTableRows(true)"/>
                </template>
            </Column>

            <Column :sortable="true" style="width:15%">
                <template #header>
                    <SearchSelect v-model="TableProps.filters['type']"
                                  :options="['Организатор', 'Оператор', 'Участник', 'Любой']"
                                  label="Тип"/>
                </template>
                <template #body="slotProps">{{ slotProps.data.type }}</template>
            </Column>


        </template>

    </TablePage>

    <AbonentCard ref="AbonentCard" :addSelectAbonentButton="addSelectAbonentButton" @abonentSelected="abonentSelected"/>

</template>

<script>
import {DataFilters, Helper, SearchInput, SearchSelect, TablePage} from "@xnpmpackages/xcomponents";
import AbonentCard from "@/components/app/GasReference/AbonentCard";

export default {
    name: "GasReference",
    components: {AbonentCard, SearchInput, SearchSelect, TablePage},
    emits: ['abonentSelected'],
    props: {
        addSelectAbonentButton: {
            type: Boolean,
            default: false
        },
        preserveLoadTableItemsOnCreate: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [DataFilters, Helper],
    data() {
        return {}
    },
    methods: {
        tableItemsClick($event) {
            this.$refs.AbonentCard.show($event.data);
        },
        abonentSelected(data) {
            this.$emit('abonentSelected', data);
        },
    },
    mounted() {
        if (!this.preserveLoadTableItemsOnCreate)
            this.$refs.TablePage.getTableRows(true);
    }
}
</script>

<style scoped>

</style>