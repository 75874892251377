import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-1 p-3 border-round" }
const _hoisted_2 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chips = _resolveComponent("Chips")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AbonentSelector = _resolveComponent("AbonentSelector")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Chips, {
        "allow-duplicate": false,
        "model-value": _ctx.innerValue,
        class: "w-full",
        onRemove: _ctx.removeDst
      }, {
        chip: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps?.value?.title), 1)
        ]),
        _: 1
      }, 8, ["model-value", "onRemove"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          icon: "fas fa-plus",
          label: "Добавить получателя",
          onClick: _ctx.addDst
        }, null, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_AbonentSelector, { ref: "AbonentSelector" }, null, 512)
  ]))
}