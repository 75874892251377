import {createRouter, createWebHashHistory} from 'vue-router';
import App from './App.vue';
import {AppEventBus} from "@xnpmpackages/xcomponents";

const routes: any = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: 'gas-requests',
                name: 'gas-requests',
                component: () => import('./components/app/GasRequests.vue')
            },
            {
                path: 'nsi-requests',
                name: 'nsi-requests',
                component: () => import('./components/app/NsiRequests.vue')
            },
            {
                path: 'sppr-requests',
                name: 'sppr-requests',
                component: () => import('./components/app/SpprRequests.vue')
            },
            {
                path: 'sppr-reports',
                name: 'sppr-reports',
                component: () => import('./components/app/SpprReports.vue')
            },
            {
                path: 'gas-reference',
                name: 'gas-reference',
                component: () => import('./components/app/GasReference.vue')
            },
            {
                path: 'nsi-reference',
                name: 'nsi-reference',
                component: () => import('./components/app/NsiReference.vue')
            },
            {
                path: 'doc-converter',
                name: 'doc-converter',
                component: () => import('./components/app/DocumentsConverter.vue')
            },
            {
                path: 'organization-info',
                name: 'organization-info',
                component: () => import('./components/app/OrganizationInfo.vue')
            },
            {
                path: 'settings',
                name: 'settings',
                component: () => import('./components/app/Params.vue')
            },
            {
                path: 'selector',
                name: 'selector_included',
                component: () => import('./components/Constructor/Selector.vue'),
                children: [
                    {
                        path: ':download',
                        name: 'selector_download',
                        component: () => import('./components/Constructor/Selector.vue'),
                    }
                ]
            },
        ]
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/constructor',
        name: 'constructor',
        component: () => import('./components/Constructor/Main.vue'),
        children: [
            {
                path: 'selector',
                name: 'selector',
                component: () => import('./components/Constructor/Selector.vue'),
                children: [
                    {
                        path: ':token',
                        name: 'token',
                        component: () => import('./components/Constructor/Selector.vue'),
                    }
                ]
            },

        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

AppEventBus.on('changeRoute', async url => {
    await router.push(url);
});

export default router;
