import {createStore} from 'vuex'
import {Xapi} from "@xnpmpackages/xcomponents";


// Create a new store instance.
const store = createStore({
    state() {
        return {

            applicationVersion: null,
            publicParams: null,

        }

    },
    mutations: {

        setApplicationVersion(state: typeof store.state, $data) {
            state.applicationVersion = $data;
        },
        setPublicParams(state, $publicParams) {
            state.publicParams = $publicParams;
        },

    },
    actions: {

        async getApplicationVersion() {

            if (process.env.NODE_ENV === 'development')
                return;

            const response = await Xapi.post(
                '/common-actions',
                {
                    action: 'getApplicationVersion',
                },
            );

            this.commit('setApplicationVersion', response.data.version);

            return response;

        },

        async getPublicParams() {

            const response = await Xapi.post('/common-actions', {
                action: 'getPublicParams',
            });

            this.commit('setPublicParams', response.data.publicParams);

            return response;

        },

    },

})


export default store;